@font-face { font-family: 'Open Sans'; font-style: normal; font-weight: 300; src: url(https://fonts.gstatic.com/s/opensans/v13/DXI1ORHCpsQm3Vp6mXoaTXhCUOGz7vYGh680lGh-uXM.woff) format('woff'); }
@font-face { font-family: 'Open Sans'; font-style: normal; font-weight: 400; src: url(https://fonts.gstatic.com/s/opensans/v13/cJZKeOuBrn4kERxqtaUH3T8E0i7KZn-EPnyo3HZu7kw.woff) format('woff'); }
@font-face { font-family: 'Open Sans'; font-style: normal; font-weight: 600; src: url(https://fonts.gstatic.com/s/opensans/v13/MTP_ySUJH_bn48VBG8sNSnhCUOGz7vYGh680lGh-uXM.woff) format('woff'); }
@font-face { font-family: 'Open Sans'; font-style: normal; font-weight: 800; src: url(https://fonts.gstatic.com/s/opensans/v13/EInbV5DfGHOiMmvb1Xr-hnhCUOGz7vYGh680lGh-uXM.woff) format('woff'); }

@font-face { font-family: 'Open Sans'; font-style: normal; font-weight: 300; src: url(https://fonts.gstatic.com/s/opensans/v13/DXI1ORHCpsQm3Vp6mXoaTegdm0LZdjqr5-oayXSOefg.woff2) format('woff2'); }
@font-face { font-family: 'Open Sans'; font-style: normal; font-weight: 400; src: url(https://fonts.gstatic.com/s/opensans/v13/cJZKeOuBrn4kERxqtaUH3VtXRa8TVwTICgirnJhmVJw.woff2) format('woff2'); }
@font-face { font-family: 'Open Sans'; font-style: normal; font-weight: 600; src: url(https://fonts.gstatic.com/s/opensans/v13/MTP_ySUJH_bn48VBG8sNSugdm0LZdjqr5-oayXSOefg.woff2) format('woff2'); }
@font-face { font-family: 'Open Sans'; font-style: normal; font-weight: 800; src: url(https://fonts.gstatic.com/s/opensans/v13/EInbV5DfGHOiMmvb1Xr-hugdm0LZdjqr5-oayXSOefg.woff2) format('woff2'); }

/* BOX MODEL */
html { -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; }
*,
*:before,
*:after { box-sizing: inherit; }

/* GENERAL STYLING */
html { height:100%; font-size:10px; padding:0; font-size:62%; }
body { font-family:'Open Sans'; color: #333333; line-height: 1.6; font-size:1.6rem; -webkit-tap-highlight-color: rgba(0, 0, 0, 0); position: relative; height:100%; padding:0; margin:0;}

h1, h2, h3, h4 , h5 { font-weight:300; margin:0 0 14px 0; color: #fff; }
h1 { font-size:4.8rem; }
h2 { font-size:3.2rem; }
h3 { font-size:2.8rem; }

p, .p { font-size:1.6rem; line-height:20px; color: #fff; margin:0 0 14px 0; }

#root {
    display: flex;
    flex: 1;
    height: 100%;
}

.logo { position: absolute; bottom: 30px; right: 30px;}