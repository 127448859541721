#main {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    background: url(../../images/kega-pand.jpg);
    background-size: cover;
    background-position: center;
}

#main .buttons {
    display: flex;
    flex-direction: row;
}

#main .buttons a {
    text-decoration:none;
}

.language_switch {
    position: absolute; top: 30px; right: 30px;
}

.language_switch button {
    padding: 0px;
    border:none;
    margin-left:20px;
}
