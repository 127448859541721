
.m-checkbox {
    display: flex;
    align-items: center;
    padding: 0.5rem;
}

.m-checkbox-label {
    flex-shrink: 0;
    padding: 0.5rem 1rem;
    color: #fff;
    cursor: pointer;
}

.m-checkbox-label a { color: #fff; }

.m-checkbox-input {
    position: relative;
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    background: rgba(0, 0, 0, 0);
    cursor: pointer;
    border: 2px solid #fff;
    border-radius: 5px;
}

.m-checkbox-input::before {
    content: ' ';
    position: absolute;
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%;
    transition: all 0.1s;
    background: #fff;
}

.m-checkbox-input:checked::before, .m-checkbox-input:indeterminate::before {
    top: 2px;
    right: 2px;
    bottom: 2px;
    left: 2px;
}

.m-checkbox-input:indeterminate::before {
    top: 7px;
    bottom: 7px;
}

.m-checkbox-input:disabled {
    border-color: #5a5358;
    cursor: default;
}

.m-checkbox-input:disabled::before {
    background-color: #5a5358;
}

.m-checkbox-input:disabled + .m-checkbox-label {
    color: #808080;
    cursor: default;
}

.m-checkbox-error-input {
    border-color: red;
    background-color: rgba(255, 0, 0, .2);
}

.m-checkbox-error-input::before {
    background-color: red;
}
