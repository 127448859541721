.react-autosuggest__container {
    position: relative;
}
  
.react-autosuggest__input {
    border: #ffffff solid 1px;
    background: rgba(255, 255, 255, 0.75);
    padding: 14px 21px;
    border-radius: 25px;
    margin-bottom: 14px;
    outline: none;
    font-size: 1.6rem;
    text-transform: uppercase;
    color: #333333;
    width: 360px;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.react-autosuggest__input.error {
    border: red solid 1px;
}


.react-autosuggest__input::placeholder {
    color: #333333;
}

.react-autosuggest__input--focused {
    outline: none;
}
  
.react-autosuggest__input--open {

}
  
.react-autosuggest__suggestions-container {
    display: none;
}
  
.react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 55px;
    width: 360px;
    border: #ffffff solid 1px;
    background: rgba(255, 255, 255, 0.95);

    font-weight: 300;
    font-size: 1.6rem;
    border-radius: 25px;
    padding: 14px 21px;
    z-index: 2;
    max-height: 400px;
    overflow-y: auto;
}
  
.react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}
  
.react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 0px;
}
  
.react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
}

.react-autosuggest__container .suggestion {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.react-autosuggest__container .name {
    padding-left: 14px;
}

.react-autosuggest__container .photo {
    width: 40px;
}