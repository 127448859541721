
.input {
    border: #ffffff solid 1px;
    background: rgba(255, 255, 255, 0.75);
    padding: 14px 21px;
    border-radius: 25px;
    margin-bottom: 14px;
    outline: none;
    font-size: 1.6rem;
    text-transform: uppercase;
    color: #333333;
    width: 360px;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

}

.input::placeholder {
    color: #333333;
}

.input_error {
    border: red solid 1px;
}
