.popup-wrapper {
    display: flex;
    position:fixed;
    top: 0;
    left: 0;
    width:100%;
    height:100%;

    align-items: center;
    justify-content: center;

    background: none repeat scroll 0% 0% rgba(0, 0, 0, 0.4);
}

.popup-container {
    display: flex;
    width: 600px;
    min-height: 300px;
    max-height: 80%;
    overflow-y: auto;

    box-shadow: 0 0 15px rgba(0, 0, 0, .6);
    background-color: white;
    padding: 20px;

    flex-direction: column;
}

.popup-container h1,
.popup-container h2,
.popup-container h3,
.popup-container h4,
.popup-container p { color: #333333; }

.popup-container .center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.popup-container .margin-top-24 {
    margin-top: 24px;
}