.nav-button { 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;  
    background: #dd005c;
    color: #fff;
    font-size: 2em;
    font-weight: 800;
    text-transform: uppercase;
    width: 250px;
    height: 250px;
    margin: 20px;
    -webkit-box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.25);
    -moz-box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.25);
    box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.25);
    opacity: 1;
}

.nav-button.down {
    background: rgba(221, 0, 92, 0.8);
}