
.button {
    border: rgba(80, 82, 79, 0.95) solid 1px;
    background: rgba(80, 82, 79, 0.95);
    padding: 14px 21px;
    border-radius: 25px;
    outline: none;
    font-size: 1.5rem;
    text-transform: uppercase;
    color: #fff;
    font-weight: 800;
    width: 360px;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    cursor:pointer;
}