#signout {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    background: url(../../images/kega-pand-binnen.jpg);
    background-size: cover;
    background-position: center;
}

#signout .background {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    width: 100%;
    /*
    animation-name: animation;
    animation-duration: 0.5s;
    animation-timing-function: ease-in-out;
    animation-play-state: running;
    animation-iteration-count: 1;
    */
    background-color: rgba(0, 0, 0, 0.3);

}

@keyframes animation {
    0%     {background-color:rgba(0, 0, 0, 0);}
    100.0%  {background-color:rgba(0, 0, 0, 0.3)}
}


#signout .form {
    display: flex;

    flex-direction: column;
    align-items: center;
}


#signout .thanx-text {
    display: flex;

    flex-direction: column;
    align-items: center;

}

#signout .thanx-text p {
    font-size: 2.1rem;
    line-height: 2.6rem;
}

#signout .thanx-text h1, #signin .thanx-text p {
    text-shadow: 0px 0px 4px rgba(0,0,0,0.65);
}